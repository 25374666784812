<template>
    <nav class="app-navigation" aria-label="Main navigation">
        <ul class="menu-list list-unstyled">
            <li
                v-for="item in navigationItems"
                :key="item.text"
                class="menu-item"
                v-test:menuItem
            >
                <b-link
                    v-if="item.textSegments"
                    v-bind="item.props"
                    class="nav-link"
                    :tabindex="tabindex"
                    v-test:navLink
                >
                    <span
                        v-for="(segment, i) in item.textSegments"
                        :key="`${ segment.text }-${ i }`"
                        :class="segment.className"
                        v-test:segment
                    >{{ segment.text }}</span>
                </b-link>
                <template v-else>
                    <b-link
                        v-bind="item.props"
                        class="nav-link nav-link-with-dropdown"
                        :tabindex="tabindex"
                        v-test:navLink
                    >
                        <span
                            v-for="(segment, i) in item.name"
                            :key="`${ segment.text }-${ i }`"
                            :class="segment.className"
                            v-test:segment
                        >{{ segment.text }}</span>
                    </b-link>
                    <b-button
                        class="btn-nav-dropdown"
                        :tabindex="tabindex"
                        variant="link"
                        @click="handleNavDropdownClick(item.id)"
                        v-test:btnNavDropdown
                    >
                        <app-icon
                            name="chevron_up"
                            class="nav-dropdown-indicator"
                            aria-hidden="true"
                        />
                    </b-button>
                    <b-collapse :id="item.id" :ref="`collapse-${ item.id }`">
                        <ul class="nav-child-list list-unstyled">
                            <li v-for="groupItem in item.groupItems" class="nav-child-list-item">
                                <b-link
                                    v-bind="groupItem.props"
                                    class="nav-child-link"
                                    v-test:childLink
                                >
                                    <span
                                        v-for="(segment, i) in groupItem.textSegments"
                                        :key="`${ segment.text }-${ i }`"
                                        :class="segment.className"
                                        v-test:segment
                                    >{{ segment.text }}</span>
                                </b-link>
                            </li>
                        </ul>
                    </b-collapse>
                </template>
            </li>
        </ul>
    </nav>
</template>

<script>
    import { BLink, BCollapse, VBToggle } from 'bootstrap-vue';
    import { mapState } from 'vuex';

    export default {
        name: 'app-navigation',

        components: { BLink, BCollapse },

        directives: {
            'b-toggle': VBToggle,
        },

        data() {
            return {
                tabindex: 0,
                groupIds: [],
            };
        },

        computed: {
            ...mapState('navigation', ['headerNavigation', 'mobileMenuVisible']),

            /**
             * Return the nav items with text segments to enable us
             * to highlight text in and including square brackets.
             *
             * @returns {{props: object, textSegments: {text: string, className: string}[]}[]}
             */
            navigationItems() {
                const headerNav = this.headerNavigation.map((group, i) => {
                    if (!group.items.length) { // don't render an empty group at all
                        return;
                    }

                    const groupItems = group.items.map((item) => ({
                        textSegments: this.segmentText(item.text),
                        props: item.props,
                    }));

                    if (groupItems.length === 1) { // if only one item in the group, render the item only
                        return groupItems[0];
                    }
                    this.groupIds.push(`group-${ i }`);
                    return {
                        id: `group-${ i }`,
                        name: this.segmentText(group.name),
                        groupItems,
                    };
                }).filter((x) => x);
                return headerNav;
            },
        },

        watch: {
            /**
             * Watch if menu opens so can update tabindex.
             *
             * @param {boolean} newValue
             */
            mobileMenuVisible(newValue) {
                if (newValue) {
                    this.tabindex = 0;
                } else {
                    this.tabindex = -1;
                }
            },

            /**
             * Close all sub menus when the route changes.
             *
             */
            $route() {
                this.groupIds.forEach((id) => {
                    const el = this.$refs[`collapse-${ id }`];

                    if (!el) {
                        return;
                    }

                    if (el[0].$el.classList.contains('show')) {
                        this.$root.$emit('bv::toggle::collapse', id);
                    }
                });
            },
        },

        mounted() {
            this.getTabindex(window.innerWidth);
        },

        methods: {
            /**
             * Determine tabindex value for links.
             *
             * @param {number} width
             */
            getTabindex(width) {
                if (!process.client) {
                    return;
                }
                if (width < 992 && !this.mobileMenuVisible) {
                    this.tabindex = -1;
                }
            },

            /**
             * Segment text so can highlight square brackets.
             *
             * @param {string} text
             * @returns {{text: string, className: string}[]}
             */
            segmentText(text) {
                if (!text) {
                    return [{
                        text,
                        className: '',
                    }];
                }
                return text.split(/(\[|\])/)
                    .filter((string) => string.trim())
                    .map((string) => ({
                        text: string,
                        className: /\[|]/g.test(string)
                            ? 'highlighted'
                            : '',
                    }));
            },

            /**
             * Handle nav dropdown click.
             *
             * @param {string} selectedId
             */
            handleNavDropdownClick(selectedId) {
                this.groupIds.forEach((id) => {
                    if (id === selectedId) {
                        this.$root.$emit('bv::toggle::collapse', selectedId);
                        return;
                    }
                    const el = this.$refs[`collapse-${ id }`];

                    if (!el) {
                        return;
                    }

                    if (el[0].$el.classList.contains('show')) {

                        this.$root.$emit('bv::toggle::collapse', id);
                    }
                });

            },
        },
    };
</script>

<style lang="scss">
    @import "~bootstrap/scss/dropdown";
</style>

<style lang="scss" scoped>
    .app-navigation {
        width: 100%;
        background-color: secondary-palette(8);
        overflow-y: scroll;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 20px 40px 40px;
        overscroll-behavior: contain;
        @media (min-width: $menu-breakpoint) {
            padding: 0;
        }
    }

    .menu-list {
        @media (min-width: $menu-breakpoint) {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 50px;
            margin-bottom: 0;
        }
    }

    .menu-item {
        padding-top: 20px;
        padding-bottom: 20px;

        @media (min-width: $menu-breakpoint) {
            padding: 0;
        }

        &:not(:last-of-type) {
            border-bottom: 2px solid secondary-palette(7);

            @media (min-width: $menu-breakpoint) {
                border-bottom: 0;
            }
        }
    }

    .nav-link {
        padding: 8px 0;
        color: $black;
        font-weight: bold;
        display: block;

        &:hover,
        &:focus-visible,
        &:active {
            color: primary-palette(1);
            text-decoration: none;
            font-weight: bold;
        }

        &.nav-link-with-dropdown {
            display: inline-flex;
        }

        &.nuxt-link-exact-active {
            @include media-breakpoint-down(md) {
                color: primary-palette(1);
            }
        }
    }

    .highlighted {
        color: primary-palette(1);
        font-weight: 400;
    }

    .btn-nav-dropdown {
        padding: 10px 0 11px;
        color: $black;
        font-weight: bold;
        display: inline-flex;
        border: 0;
        border-radius: 0;
        margin-left: 10px;

        @media (min-width: $menu-breakpoint) {
            padding: 30px 0;
        }

        &:focus {
            color: $black;
            text-decoration: none;
        }

        &:hover,
        &:focus-visible,
        &:active {
            color: $black;
            text-decoration: none;

            > span::after {
                border-bottom-color: primary-palette(1);
            }
        }

        > span {
            position: relative;

            &::after {
                @media (min-width: $menu-breakpoint) {
                    border-bottom: 2px solid transparent;
                    position: absolute;
                    bottom: -5px;
                    left: 0;
                    right: 0;
                    content: '';
                    transition: border-color 0.3s;
                }
            }
        }

        &.not-collapsed {
            .nav-dropdown-indicator {
                transform: rotate(0);
                color: primary-palette(1);
            }

            > span::after {
                border-bottom-color: primary-palette(1);
            }
        }
    }

    .nav-dropdown-indicator {
        transform: rotate(180deg);
        transition: transform 0.3s ease;
        margin-left: auto;
    }

    .collapse,
    .collapsing {
        @media (min-width: $menu-breakpoint) {
            position: absolute;
            top: 80px;
            left: 0;
            right: 0;
            background-color: $white;
            padding-top: 22px;
            padding-bottom: 22px;
            border-top: 2px solid secondary-palette(7);
            text-align: center;
        }
    }

    .nav-child-list {
        padding-bottom: 10px;

        @media (min-width: $menu-breakpoint) {
            display: inline-flex;
            justify-content: center;
            padding-bottom: 0;
        }
    }

    .nav-child-list-item {
        @media (min-width: $menu-breakpoint) {
            flex: 1 0 0;
            text-align: center;
            white-space: nowrap;
            padding-left: 20px;
            padding-right: 20px;
        }
    }

    .nav-child-link {
        padding: 10px 0;
        color: $black;
        font-weight: 250;
        display: block;
        @media (min-width: $menu-breakpoint) {
            padding: 0 10px;
        }

        &:hover,
        &:focus-visible,
        &:active {
            color: primary-palette(1);
            text-decoration: none;
        }

        &.nuxt-link-exact-active {
            @media (max-width: ($menu-breakpoint - 1)) {
                color: primary-palette(1);
            }
        }
    }
</style>
